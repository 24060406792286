import axios from "axios";

const URI = process.env.REACT_APP_API_URI+"/api/sii_section/";
const _user = JSON.parse(window.localStorage.getItem("loggedRabenUser"));

export class Sii_sectionService {
    async get(rut) {
        return await axios.get(URI + rut,
            {
                headers: {
                    authorization: _user.token,
                },
            }).then((res) => res.data);
    }    
}

import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Route, useLocation, Link, useHistory } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import { AppConfig } from "./AppConfig";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import Dashboard from "./components/Dashboard";

import Client from "./pages/Client";
import clientDetail from "./pages/clientDetail";
import operationDetail from "./pages/operationDetail";
import operationsAvailable from "./pages/operationsAvailable";

import User from "./pages/User";
import Login from "./pages/Login";
import Register from "./pages/Register";
import PrimeReact from "primereact/api";

import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "./App.scss";

const App = () => {
    const [layoutMode, setLayoutMode] = useState("static");
    const [layoutColorMode, setLayoutColorMode] = useState("light");
    const [inputStyle, setInputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const [user, setUser] = useState(null);
    const copyTooltipRef = useRef();
    const location = useLocation();
    const [sessionExpiredDialog, setsessionExpiredDialog] = useState(false);
    const history = useHistory();

    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        const loggedUserJson = window.localStorage.getItem("loggedRabenUser");
        if (loggedUserJson) {
            const _user = JSON.parse(loggedUserJson);
            setUser(_user);
        }

        let allSecondsRemaining = setInterval(() => {
            console.log(getTimeRemain());
            if (getTimeRemain() <= 0) {
                history.push('/')
                setsessionExpiredDialog(true);
            }
        }, 1000);
    }, []);

    function getTimeRemain() {
        const timerLength =3600;
        const memberInLocalStorage = window.localStorage.getItem("member") || "";
        if (memberInLocalStorage) {
            const member = JSON.parse(atob(memberInLocalStorage));
            const issuedSeconds = isNaN(member.issuedSeconds) ? 0 : member.issuedSeconds;
            const secondsSinceSignIn = Number(Math.floor(new Date().getTime() / 1000) - issuedSeconds);
            const sessionSecondsRemaining = Number(timerLength - secondsSinceSignIn);
            return sessionSecondsRemaining;
        }
    }

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode);
    };

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode);
    };

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === "overlay") {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }
                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };
    const isDesktop = () => {
        return window.innerWidth >= 992;
    };

    const menu = [
        {
            label: "Inicio",
            items: [{ label: "Panel principal", icon: "pi pi-fw pi-home", to: "/" }],
        },
        {
            label: "Modulos",
            icon: "pi pi-fw pi-sitemap",
            items: [
                { label: "Mis Clientes", icon: "pi pi-fw pi-users", to: "/client" },
                { label: "Mis Operaciones", icon: "pi pi-fw pi-list", to: "/operationsAvailable" },
            ],
        },
        {
            label: "Configuración",
            icon: "pi pi-fw pi-cog",
            items: [{ label: "Usuarios", icon: "pi pi-fw pi-user-edit", to: "/user" }],
        },
    ];

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
        "layout-theme-light": layoutColorMode === "light",
    });

    const hideDialog = () => {
        setUser(null);
        window.localStorage.removeItem("loggedRabenUser");
        window.localStorage.removeItem("member");
        setsessionExpiredDialog(false);
    };
    const sessionExpiredFooter = (
        <>
            <Button label="Aceptar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </>
    );

    const renderMain = () => {
        const loggedUserJson = JSON.parse(window.localStorage.getItem("loggedRabenUser"));
        const admin = loggedUserJson.roleId == 1 ? true : false;
        let _menu;

        if (!admin) {
            _menu = menu.filter((el) => "Configuración" !== el.label);
        } else {
            _menu = menu;
        }

        return (
            <div>
                {<AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode} mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />}

                <div className="layout-sidebar" onClick={onSidebarClick}>
                    <AppMenu model={_menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                </div>

                <div className="layout-main-container">
                    <div className="layout-main">
                        <Route path="/" exact render={() => <Dashboard colorMode={layoutColorMode} location={location} />} />
                        <Route path="/client" component={Client} />
                        <Route path="/clientDetail/:id" component={clientDetail} />
                        <Route path="/operationDetail/:id" component={operationDetail} />
                        {admin && <Route path="/user" component={User} />}
                        <Route path="/operationsAvailable" component={operationsAvailable} />
                        <Route path="/Register" component={Register} />
                    </div>

                    <AppFooter layoutColorMode={layoutColorMode} />
                </div>

                <Dialog visible={sessionExpiredDialog} style={{ width: "450px" }} header="Sesión expirada" modal className="p-fluid" footer={sessionExpiredFooter} onHide={hideDialog}>
                    <div className="flex align-items-center justify-content-center">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />

                        <span>
                            Lo sentimos, la sesión ha <b>expirado</b>.
                        </span>
                    </div>
                </Dialog>
            </div>
        );
    };

    const renderPublic = () => {
        return (
            <div className="layout-main-container">
                <div className="layout-main">
                    <Route path="/Register" component={Register} />
                    <Route path="/" exact render={() => <Login />} />
                </div>
            </div>
        );
    };

    const initialForm = () => {
        // const isLoggedIn = props.isLoggedIn;
        if (user) {
            return renderMain();
        }
        return renderPublic();
    };

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            {initialForm()}

            <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange} layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>
        </div>
    );
};

export default App;

import React, { useState, useRef } from "react";
import classNames from "classnames";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { LoginService } from "../service/LoginService";

const Login = () => {
    let emptyLogin = {
        email: "",
        password: "",
    };

    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);

    const [login, setLogin] = useState(emptyLogin);

    const submittedLogin = () => {
        setSubmitted(true);

        if (login.email.trim() && login.password.trim()) {
            const loginService = new LoginService();
            if (login.email) {
                loginService
                    .login(login)
                    .then((data) => {
                        window.localStorage.setItem("loggedRabenUser", JSON.stringify(data));
                        toast.current.show({ severity: "success", summary: "Correcto", detail: "Autenticado", life: 3000 });
                        window.localStorage.setItem(
                            'member',
                            window.btoa(
                              JSON.stringify({
                                issuedSeconds: Math.floor(new Date().getTime() / 1000),
                              })
                            )
                          );
                        window.location.reload();
                    })
                    .catch((error) => {
                        toast.current.show({ severity: "error", summary: "Usuario o contraseña incorrectos", detail: "Error", life: 3000 });
                    });
            }
        }
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _login = { ...login };
        _login[`${name}`] = val;
        setLogin(_login);
    };

    return (
        <div>
            <Toast ref={toast} />
            <div className="flex">
                <div className="surface-section w-full md:w-6 p-6 md:p-6">
                    <div className="mb-5">
                        <img src="assets/layout/images/logo-raben.png" alt="Raben" height="50" className="mb-3" />
                        <div className="text-900 text-3xl font-medium mb-3">Bienvenido</div>
                        <span className="text-600 font-medium mr-2">Si no posees una cuenta contacta con el administrador</span>
                    </div>

                    <div>
                        <div className="field">
                            <label htmlFor="email">Email</label>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-at"></i>
                                </span>
                                <InputText id="email" value={login.email} onChange={(e) => onInputChange(e, "email")} required className={classNames({ "p-invalid": submitted && !login.email })} />
                            </div>
                            {submitted && !login.email && <small className="p-invalid">El email es obligatorio</small>}
                        </div>
                    </div>

                    <div className="field">
                        <label htmlFor="sii_pass">Contraseña</label>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-lock"></i>
                            </span>
                            <InputText type="password" id="password" value={login.password} onChange={(e) => onInputChange(e, "password")} required className={classNames({ "p-invalid": submitted && !login.password })} />
                        </div>
                        {submitted && !login.password && <small className="p-invalid">La clave es obligatoria</small>}
                    </div>
                    <div className="flex align-items-center justify-content-between mb-6"></div>
                    <Button label="Iniciar sesion" icon="pi pi-user" className="p-button p-component w-full" onClick={submittedLogin} />
                </div>
                <div className="surface-section md:w-10 p-6 md:p-0">
                <img src={'../assets/layout/images/banner.jpg'} height={"600"} width={"auto"} alt="logo" />
                </div>
            </div>
            <div className="hidden md:block w-6 bg-no-repeat bg-cover"></div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Login, comparisonFn);

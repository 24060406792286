import axios from 'axios';

const URI = process.env.REACT_APP_API_URI+'/api/dashboard/';
const _user = JSON.parse(window.localStorage.getItem("loggedRabenUser"));

export class DashboardService {

    async getResponses7d() {
        return await axios.get(URI +"reslast7d/",
        {
            headers: {
                authorization: _user.token,
            },
        }).then(res => res.data);
    }
    async getResponses24h() {
        return await axios.get(URI +"reslast24h/",
        {
            headers: {
                authorization: _user.token,
            },
        }).then(res => res.data);
    }
    async getUsers7d() {
        return await axios.get(URI +"userlast7d/",
        {
            headers: {
                authorization: _user.token,
            },
        }).then(res => res.data);
    }
    async getUsersTotal() {
        return await axios.get(URI +"usertotal/",
        {
            headers: {
                authorization: _user.token,
            },
        }).then(res => res.data);
    }
    async getClients7d() {
        return await axios.get(URI +"clientlast7d/",
        {
            headers: {
                authorization: _user.token,
            },
        }).then(res => res.data);
    }
    async getClientsTotal() {
        return await axios.get(URI +"clienttotal/",
        {
            headers: {
                authorization: _user.token,
            },
        }).then(res => res.data);
    }   
}
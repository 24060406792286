import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import { DashboardService } from "../service/DashboardService";
import { Button } from "primereact/button";
import { Sii_responseService } from "../service/Sii_responseService";

const Dashboard = (props) => {
    const [lineOptions, setLineOptions] = useState(null);
    const [resLast7d, setResLast7d] = useState(null);
    const [resLast24h, setResLast24h] = useState(null);
    const [usersLast7d, setUsersLast7d] = useState(null);
    const [usersTotal, setUsersTotal] = useState(null);
    const [clients7d, setClients7d] = useState(null);
    const [clientsTotal, setClientsTotal] = useState(null);

    const applyLightTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: "#495057",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
                y: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
            },
        };

        setLineOptions(lineOptions);
    };

    const applyDarkTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: "#ebedef",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#ebedef",
                    },
                    grid: {
                        color: "rgba(160, 167, 181, .3)",
                    },
                },
                y: {
                    ticks: {
                        color: "#ebedef",
                    },
                    grid: {
                        color: "rgba(160, 167, 181, .3)",
                    },
                },
            },
        };

        setLineOptions(lineOptions);
    };
    useEffect(() => {
        if (props.colorMode === "light") {
            applyLightTheme();
        } else {
            applyDarkTheme();
        }
    }, [props.colorMode]);

    useEffect(() => {
        const dashboardService = new DashboardService();
        dashboardService.getResponses7d().then((data) => {
            setResLast7d(data);
        });
        dashboardService.getResponses24h().then((data) => {
            setResLast24h(data);
        });
        dashboardService.getClients7d().then((data) => {
            setClients7d(data);
        });
        dashboardService.getClientsTotal().then((data) => {
            setClientsTotal(data);
        });
        dashboardService.getUsers7d().then((data) => {
            setUsersLast7d(data);
        });
        dashboardService.getUsersTotal().then((data) => {
            setUsersTotal(data);
        });
    }, []);

    function chatLinearData() {
        var _labels = [];
        var _data = [];

        if (resLast7d) {
            resLast7d.forEach((element) => {
                _labels.push(formatFullDate(element.fecha));
                _data.push(element.request);
            });
        }
        const lineData = {
            labels: _labels,
            datasets: [
                {
                    label: "Número de consultas al SII",
                    data: _data,
                    fill: false,
                    backgroundColor: "#2f4860",
                    borderColor: "#2f4860",
                    tension: 0.4,
                },
            ],
        };
        return lineData;
    }
    const successResponse = () => {
        let success = 0;
        if (resLast24h) {
            resLast24h.forEach((element) => {
                if (element.response_code == 200) {
                    success = element.request;
                }
            });
        }
        return success;
    };
    const errorResponse = () => {
        let error = 0;
        if (resLast24h) {
            resLast24h.forEach((element) => {
                if (element.response_code != 200) {
                    error = error + parseInt(element.request);
                }
            });
        }
        return error;
    };

    const totalResponse = () => {
        let total = 0;
        if (resLast24h) {
            resLast24h.forEach((element) => {
                total = total + parseInt(element.request);
            });
        }
        return total;
    };

    const formatFullDate = (value) => {
        var date = value.split("T");
        var _date = date[0].split("-");
        return _date[2] + "-" + _date[1] + "-" + _date[0];
    };
    const siiService = new Sii_responseService();

    // const event = async () => {
    //     let responses = await siiService.resetDocumentTable().then();

    //     for (const row of responses) {
    //         var _response = JSON.parse(row.response);
    //         console.log(_response.data);
    //         for (const element of _response.data) {
    //             await siiService.setDocuments(row.rut,element,row.type);
    //         }
    //     }

    //     // (d) => {
    //     //   d.forEach((row) => {
    //     //       var _response = JSON.parse(row.response);
    //     //       _response.data.forEach((element) => {
    //     //           setDocument(_rut, element, row.type);
    //     //       });

    //     //   });
    //     // }
    // };

    return (
        <div className="grid">
            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Consultas SII en las últimas 24hr</span>
                            <div className="text-900 font-medium text-xl">{totalResponse()}</div>
                            {/* <Button label="Evento" icon="pi pi-user-plus" className="p-button-success mr-2" onClick={event} /> */}
                        </div>
                        <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                            <i className="pi pi-sort-up text-blue-500 text-xl" />
                        </div>
                    </div>
                    <span className="text-green-500 font-medium">Limite diario </span>
                    <span className="text-500"> 1390</span>
                </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Resumen de consultas las últimas 24hr</span>
                            <div className="text-600 font-medium">
                                <span className="text-green-600 font-medium text-xl">{successResponse()} </span>Consultas exitosas
                            </div>
                            <div className="text-600 font-medium">
                                <span className="text-pink-600 font-medium text-xl">{errorResponse()} </span>Consultas con errores
                            </div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                            <i className="pi pi-bars text-orange-500 text-xl" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Resumen de Clientes</span>
                            <div className="text-900 font-medium text-xl">{clientsTotal?.clients}</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                            <i className="pi pi-inbox text-cyan-500 text-xl" />
                        </div>
                    </div>
                    <span className="text-green-500 font-medium">{clients7d?.clients} </span>
                    <span className="text-500">Agregados los últimos 7 días</span>
                </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Resumen de Usuarios</span>
                            <div className="text-900 font-medium text-xl">{usersTotal?.users} </div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-purple-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                            <i className="pi pi-user text-purple-500 text-xl" />
                        </div>
                    </div>
                    <span className="text-green-500 font-medium">{usersLast7d?.users} </span>
                    <span className="text-500">Agregados los últimos 7 días</span>
                </div>
            </div>
            <div className="col-12 lg:col-12">
                <div className="card">
                    <h5>Resumen de consultas los últimos 7 días</h5>
                    <Chart type="line" data={chatLinearData()} options={lineOptions} />
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};

export default React.memo(Dashboard, comparisonFn);

export class SessionService {
    async getSessionToken() {
        const loggedUserJson = window.localStorage.getItem("loggedRabenUser");
        if (loggedUserJson) {
            const _user = JSON.parse(loggedUserJson);
            return await _user.token;
        } else {
            window.location.reload();
        }
    }

    async getSessionData() {
        const loggedUserJson = window.localStorage.getItem("loggedRabenUser");
        if (loggedUserJson) {
            const _user = JSON.parse(loggedUserJson);
            return await _user;
        } else {
            window.location.reload();
        }
    }
}

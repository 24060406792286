import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";

import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { ClientService } from "../service/ClientService";
import { Sii_responseService } from "../service/Sii_responseService";
import { UserService } from "../service/UserService";
import { Loading } from "../components/Loading";
// import { ReCAPTCHA} from "react-google-recaptcha"

const _user = JSON.parse(window.localStorage.getItem("loggedRabenUser"));

const Crud = () => {
    let emptyClient = {
        id: null,
        name: "",
        rut: "",
        fullRut: "",
        dv: "",
        sii_pass: "",
        email: "",
        phone: "",
        createdAt: "",
        updatedAt: "",
        userId: "",
        stateId: 1,
    };

    const [clientDialog, setClientDialog] = useState(false);
    const [client, setClient] = useState(emptyClient);
    const [users, setUsers] = useState(null);

    const [submitted, setSubmitted] = useState(false);

    const [loaded, setLoader] = useState(true);

    const toast = useRef(null);

    useEffect(() => {
        const userService = new UserService();
        userService.getUsers().then((data) => setUsers(data));
        setLoader(false);
    }, []);

    var Fn = {
        // Valida el rut con su cadena completa "XXXXXXXX-X"
        validaRut: function (rutCompleto) {
            rutCompleto = rutCompleto.replace("‐", "-");
            if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) {
                return false;
            }

            var tmp = rutCompleto.split("-");
            var digv = tmp[1];
            var rut = tmp[0];

            if (digv === "K") digv = "k";

            return Fn.dv(rut) == digv;
        },
        dv: function (T) {
            var M = 0,
                S = 1;
            for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;

            return S ? S - 1 : "k";
        },
    };

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };

    const validatePhone = (phone) => {
        return String(phone)
            .toLowerCase()
            .match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/);
    };

    const onSelectChange = (e) => {
        const val = (e.target && e.target.value) || "";
        let _client = { ...client };
        _client[`userId`] = val;
        setClient(_client);
    };

    const saveClient = async () => {
        setSubmitted(true);
        if (client.name.trim() && client.fullRut.trim() && client.sii_pass.trim()) {
            if (Fn.validaRut(client.fullRut) && validateEmail(client.email)) {
                var tmp = client.fullRut.split("-");
                var rut = tmp[0];
                const clientService = new ClientService();
                var valClient = await clientService.getClientByRut(rut).then();

                if (!valClient || client.id) {
                    setLoader(true);

                    let _client = { ...client };

                    clientService
                        .setPublicClient(_client)
                        .then((d) => {
                            // setClient(emptyClient);
                            toast.current.show({ severity: "success", summary: "Correcto", detail: "Registro ingresado correctamente", life: 3000 });
                            setLoader(false);
                            setClientDialog(true);
                        })
                        .catch((error) => {
                            setLoader(false);
                            toast.current.show({ severity: "error", summary: "Error en la comunicación", detail: error.response.data.message, life: 3000 });
                        });
                } else {
                    toast.current.show({ severity: "error", summary: "Error en ingreso de rut", detail: "Existen registros asociados al rut ingresado, por favor contacte un ejecutivo directamente", life: 3000 });
                }
            }
        }
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _client = { ...client };
        _client[`${name}`] = val;

        setClient(_client);
    };
    const clientDialogFooter = (
        <>
            <a href="https://rabenfactoring.cl/">
                <Button label="Aceptar" icon="pi pi-check" className="p-button-text" />
            </a>
        </>
    );

    return (
        <div className="grid crud-demo">
            {loaded && <Loading />}
            <Toast ref={toast} />
            <div className="col-10">
            
                <div className="card">
                <img src="assets/layout/images/logo-raben.png" alt="Raben" height="50" />
                    <h4>Formulario de registro</h4>
                    <div className="field">
                        <label htmlFor="name">Nombre / Razón social</label>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-user"></i>
                            </span>
                            <InputText id="name" maxLength="99" value={client.name} onChange={(e) => onInputChange(e, "name")} required autoFocus className={classNames({ "p-invalid": submitted && !client.name })} />
                        </div>
                        {submitted && !client.name && <small className="p-invalid">Nombre es obligatorio</small>}
                    </div>
                    <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="fullRut">Rut</label>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-id-card"></i>
                                </span>
                                <InputText id="fullRut" value={client.fullRut} onChange={(e) => onInputChange(e, "fullRut")} required className={classNames({ "p-invalid": submitted && (!client.fullRut || !Fn.validaRut(client.fullRut)) })} />
                            </div>
                            {submitted && !Fn.validaRut(client.fullRut) && (
                                <small className="p-invalid">
                                    El rut ingresado no es valido <br></br>
                                </small>
                            )}
                            {submitted && !client.fullRut && <small className="p-invalid">El rut es obligatorio</small>}
                        </div>
                    </div>
                    <div className="field">
                        <label htmlFor="email">Email</label>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-at"></i>
                            </span>
                            <InputText id="email" maxLength="100" value={client.email} onChange={(e) => onInputChange(e, "email")} required className={classNames({ "p-invalid": submitted && (!client.email || !validateEmail(client.email)) })} />
                        </div>
                        {submitted && !validateEmail(client.email) && (
                            <small className="p-invalid">
                                El email ingresado no es valido <br></br>
                            </small>
                        )}
                        {submitted && !client.email && <small className="p-invalid">El email es obligatorio</small>}
                    </div>
                    <div className="field">
                        <label htmlFor="phone">Teléfono</label>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-phone"></i>
                            </span>
                            <InputText id="phone" maxLength="40" value={client.phone} onChange={(e) => onInputChange(e, "phone")} required className={classNames({ "p-invalid": submitted && (!client.phone || !validatePhone(client.phone)) })} />
                        </div>
                        {submitted && !validatePhone(client.phone) && (
                            <small className="p-invalid">
                                El teléfono ingresado no es valido <br></br>
                            </small>
                        )}
                        {submitted && !client.phone && <small className="p-invalid">El teléfono es obligatorio</small>}
                    </div>
                    <div className="field">
                        <label htmlFor="sii_pass">Contraseña SII</label>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-lock"></i>
                            </span>
                            <InputText maxLength="40" type="password" id="sii_pass" value={client.sii_pass} onChange={(e) => onInputChange(e, "sii_pass")} required className={classNames({ "p-invalid": submitted && !client.sii_pass })} />
                        </div>
                        {submitted && !client.sii_pass && <small className="p-invalid">La clave es obligatoria</small>}
                    </div>
                    <div className="field">
                        <label htmlFor="name">Ejecutivo</label>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-user"></i>
                            </span>
                            <Dropdown value={client.userId} onChange={(e) => onSelectChange(e)} options={users} required className={classNames({ "p-invalid": submitted && !client.userId })} optionLabel="fullName" optionValue="id" placeholder="Seleccione Ejecutivo" />
                        </div>
                        {submitted && !client.userId && <small className="p-invalid">El ejecutivo es obligatorio</small>}
                    </div>
                    <div className="field">
                        <a href="https://rabenfactoring.cl/">
                            <Button label="Volver" icon="pi pi-times" className="p-button-text" />
                        </a>
                        <Button label="Registrar" icon="pi pi-check" className="p-button-text" onClick={saveClient} />
                    </div>
                    <br></br>* Al presionar “Registrar” ud acepta <a href="https://rabenfactoring.cl/politicas-de-privacidad/">políticas de privacidad</a> de la pagina.
                </div>
            </div>

            <Dialog visible={clientDialog} style={{ width: "450px" }} header="Cliente registrado" modal footer={clientDialogFooter}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi pi-check mr-3" style={{ fontSize: "2rem" }} />
                    <span>
                        Se ha registrado correctamnte <b>{client.name}</b>?
                    </span>
                </div>
            </Dialog>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Crud, comparisonFn);

import axios from "axios";

const URI = process.env.REACT_APP_API_URI + "/api/user/";
const _user = JSON.parse(window.localStorage.getItem("loggedRabenUser"));

export class UserService {
    async getUsers() {
        return await axios
            .get(URI)
            .then((res) => res.data);
    }

    async getUser(id) {
        return await axios
            .get(URI+"id/" + id, {
                headers: {
                    authorization: _user.token,
                },
            })
            .then((res) => res.data);
    }

    async getUserByEmail(email) {
        return await axios
            .get(URI+"email/" + email, {
                headers: {
                    authorization: _user.token,
                },
            })
          
            .then((res) => res.data);
    }

    async setUser(user) {
        return await axios
            .post(
                URI,
                {
                    first_name: user.first_name,
                    last_name: user.last_name,
                    email: user.email,
                    password: user.password,
                    roleId: user.roleId,
                    stateId: 4,
                },
                {
                    headers: {
                        authorization: _user.token,
                    },
                }
            )
            .then((res) => res.data);
    }

    async updateUser(user) {
        return await axios
            .put(
                URI + user.id,
                {
                    first_name: user.first_name,
                    last_name: user.last_name,
                    email: user.email,
                    password: user.password,
                    stateId: user.stateId,
                    roleId: user.roleId,
                },
                {
                    headers: {
                        authorization: _user.token,
                    },
                }
            )
            .then((res) => res.data);
    }

    async deleteUser(id) {
        return await axios
            .delete(URI + id, {
                headers: {
                    authorization: _user.token,
                },
            })
            .then((res) => res.data);
    }
}

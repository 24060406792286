import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";

export const TableTransfersClient = (props) => {
    const [expandedRows, setExpandedRows] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);

    useEffect(() => {

    }, []);

    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.period);
    };
    const formatDate = (value) => {
        var date = value.split("T");
        var _date = date[0].split("-")
        return _date[1]+"-"+_date[0];
    };
    const formatFullDate = (value) => {
        var date = value.split("T");
        var _date = date[0].split("-")
        return _date[2]+"-"+_date[1]+"-"+_date[0];
    };
    const formatCurrency = (value) => {
        return value.toLocaleString("es-CL", { style: "currency", currency: "CLP" });
    };
    const subDateBodyTemplate = (rowData) => {
        return formatFullDate(rowData.FCH_EMIS_DTE);
    };
    const subNombreDocTemplate = (rowData) => {
        return rowData.NOMBRE_DOC;
    };
    const subFolioTemplate = (rowData) => {
        return rowData.FOLIO_DOC;
    };
    const subRutTemplate = (rowData) => {
        return rowData.DEUDOR;
    };
    const rutCesionSocialTemplate = (rowData) => {
        return rowData.CESIONARIO;
    };

    const subFecVencimientoBodyTemplate = (rowData) => {
        return formatFullDate(rowData.FCH_VENCIMIENTO);
    };

    const subFecCesionBodyTemplate = (rowData) => {
        var _date= new Date(rowData.FCH_CESION)
        return _date.toLocaleString('es-CL',{
            year: 'numeric', month: 'numeric', day: 'numeric',
          });
    };
    const nameCesionBodyTemplate = (rowData) => {
        return rowData.RZ_CESIONARIO
    };
    const subMontoCesionBodyTemplate = (rowData) => {
        return formatCurrency(parseInt(rowData.MNT_CESION));
    };
    const subTotalBodyTemplate = (rowData) => {
        return formatCurrency(parseInt(rowData.MNT_TOTAL));
    };
    const subHeader = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h6 className="m-0">Listado de documentos</h6>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const Header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Cesiones</h5>
        </div>
    );

    const rowExpansionTemplate = (data) => {
        return (
            <div className="orders-subtable">
                <DataTable
                    value={data.data}
                    responsiveLayout="scroll"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    header={subHeader}
                    globalFilter={globalFilter}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} documentos"
                    emptyMessage="No se encontraron documentos."
                >
                    <Column field="FCH_EMIS_DTE" header="Fecha Emisión" body={subDateBodyTemplate}></Column>
                    <Column field="FOLIO_DOC" header="Folio" sortable body={subFolioTemplate}></Column>
                    <Column field="NOMBRE_DOC" header="Nombre documento" body={subNombreDocTemplate}></Column>
                    <Column field="DEUDOR" header="Rut deudor" sortable body={subRutTemplate}></Column>
                    <Column field="CESIONARIO" header="Rut Cesionario" sortable body={rutCesionSocialTemplate}></Column>
                    <Column field="RZ_CESIONARIO" header="Razón social cesionario" body={nameCesionBodyTemplate}></Column>
                    <Column field="FCH_CESION" header="Fecha de cesion" body={subFecCesionBodyTemplate}></Column>
                    {/* <Column field="FCH_VENCIMIENTO" header="Fecha de vencimiento cesión" body={subFecVencimientoBodyTemplate}></Column> */}
                    <Column field="MNT_CESION" header="Monto cesión" body={subMontoCesionBodyTemplate}></Column>
                    <Column field="MNT_TOTAL" header="Total" body={subTotalBodyTemplate}></Column>
                </DataTable>
            </div>
        );
    };

    return (
        <div className="col-12">
            <div className="card">
                <DataTable value={props.model} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} responsiveLayout="scroll" rowExpansionTemplate={rowExpansionTemplate} dataKey="period" header={Header}>
                    <Column expander style={{ width: "3em" }} />
                    <Column field="period" header="Periodo" sortable body={dateBodyTemplate} />
                    {/* <Column field="neto_periodo" header="Neto" body={NetoBodyTemplate} />
                    <Column field="iva_periodo" header="IVA" body={ivaBodyTemplate} />
                    <Column field="total_periodo" header="Total" body={TotalBodyTemplate} /> */}
                </DataTable>
            </div>
        </div>
    );
};

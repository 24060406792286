import React, { useEffect } from "react";

export const Loading = (props) => {
    useEffect(() => {}, []);

    return (
        <pre style={props.style}>
            <div className="center-screen">
            </div>
        </pre>
    );
};
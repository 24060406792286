import axios from "axios";

const URI_RCVR = process.env.REACT_APP_API_URI + "/api/sii_response_rcvr/";
const URI_RCVD = process.env.REACT_APP_API_URI + "/api/sii_response_rcvd/";
const URI_RTCL = process.env.REACT_APP_API_URI + "/api/sii_response_rtcl/";
const URI_UPDATSIIPERIODS = process.env.REACT_APP_API_URI + "/api/sii_update_current_period/";
const _user = JSON.parse(window.localStorage.getItem("loggedRabenUser"));

export class Sii_responseService {

    async getRCVD(rut, clave,_type, verify) {
        return await axios
            .post(
                URI_RCVD,
                {
                    rut: rut,
                    type: _type,
                    sii_password: clave,
                    verify: verify,
                },
                {
                    headers: {
                        authorization: _user.token,
                    },
                }
            )
            .then((res) => res.data);
    }

    async getRTCL(rut, _type) {
        return await axios
            .post(
                URI_RTCL,
                {
                    rut: rut,
                    type: _type,
                },
                {
                    headers: {
                        authorization: _user.token,
                    },
                }
            )
            .then((res) => res.data);
    }

    async getRCVR(rut, _type) {
        return await axios
            .post(
                URI_RCVR,
                {
                    rut: rut,
                    type: _type,
                },
                {
                    headers: {
                        authorization: _user.token,
                    },
                }
            )
            .then((res) => res.data);
    }
    
    async updateResponsePeriods(rut) {
        return await axios
            .post(
                URI_UPDATSIIPERIODS,
                {
                    rut: rut,
                },
                {
                    headers: {
                        authorization: _user.token,
                    },
                }
            )
            .then((res) => res.data);
    }


    async resetDocumentTable() {
        return await axios
            .get(
                process.env.REACT_APP_API_URI + "/api/resetDocumentTable/",
                {
                    headers: {
                        authorization: _user.token,
                    },
                }
            )
            .then((res) => res.data);
    }

    async setDocuments(rut,doc,type) {
        return await axios
            .post(
                process.env.REACT_APP_API_URI + "/api/setDocuments/",
                {
                    rut: rut,
                    doc: doc,
                    type: type
                },
                {
                    headers: {
                        authorization: _user.token,
                    },
                }
            )
            .then((res) => res.data);
    }
}

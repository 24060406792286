import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { UserService } from "../service/UserService";
import { Dropdown } from "primereact/dropdown";

const Crud = () => {
    let emptyUser = {
        id: null,
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        stateId: "",
        roleId: "",
    };

    const [users, setUsers] = useState(null);
    // const [roleValue, setRoleValue] = useState(null);
    const [userDialog, setUserDialog] = useState(false);
    const [deleteUserDialog, setDeleteUserDialog] = useState(false);
    const [user, setUser] = useState(emptyUser);
    const [selectedUser, setSelectedUser] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        const userService = new UserService();
        userService.getUsers().then((data) => setUsers(data));
    }, []);

    const _user = JSON.parse(window.localStorage.getItem("loggedRabenUser"));
    const openNew = () => {
        setUser(emptyUser);
        setSubmitted(false);
        setUserDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setUserDialog(false);
    };

    const hideDeleteUserDialog = () => {
        setDeleteUserDialog(false);
    };

    const saveUser = async () => {
        setSubmitted(true);
        if (user.first_name && user.last_name && user.email && user.password && user.roleId) {
            if (validateEmail(user.email) && validatePassword(user.password)) {
                const userService = new UserService();
                var valUser = await userService.getUserByEmail(user.email).then();
                
                if (!valUser || user.id) {
                    let _user = { ...user };
                    if (user.id) {
                        userService.updateUser(_user).then((d) => {
                            userService.getUsers().then((data) => setUsers(data));
                            setUser(emptyUser);
                            toast.current.show({ severity: "success", summary: "Correcto", detail: "Usuario Actualizado", life: 3000 });
                        });
                    } else {
                        userService.setUser(_user).then((d) => {
                            userService.getUsers().then((data) => setUsers(data));
                            setUser(emptyUser);
                            toast.current.show({ severity: "success", summary: "Correcto", detail: "Usuario Creado", life: 3000 });
                        });
                    }
                    setUserDialog(false);
                } else {
                    toast.current.show({ severity: "error", summary: "Error en ingreso de email", detail: "El email ingresado ya existe", life: 3000 });
                }

               
            }
        }
    };

    const validatePassword = (password) => {
        var a = String(password).match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!%*?&]{6,15}$/);
        return a;
    };

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };

    const editUser = (user) => {
        setUser({ ...user });
        setUserDialog(true);
    };

    const confirmDeleteUser = (user) => {
        setUser(user);
        setDeleteUserDialog(true);
    };

    const deleteUser = () => {
        const userService = new UserService();
        userService
            .deleteUser(user.id)
            .then((d) => {
                userService.getUsers().then((data) => setUsers(data));
                setDeleteUserDialog(false);
                setUser(emptyUser);
                toast.current.show({ severity: "success", summary: "Correcto", detail: "Usuario Eliminado", life: 3000 });
            })
            .catch((error) => {
                setUser(emptyUser);
                setDeleteUserDialog(false);
                toast.current.show({ severity: "error", summary: error.message, detail: "Error", life: 3000 });
            });
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _user = { ...user };
        _user[`${name}`] = val;
        setUser(_user);
    };

    const roleSelectItems = [
        { name: "Administrador", code: 1 },
        { name: "Operador", code: 2 },
    ];

    const onSelectChange = (e) => {
        const val = (e.target && e.target.value) || "";
        let _user = { ...user };
        _user[`roleId`] = val;
        setUser(_user);
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo Usuario" icon="pi pi-user-plus" className="p-button-success mr-2" onClick={openNew} />
                <Button label="Exportar" icon="pi pi-download" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Id</span>
                {rowData.id}
            </>
        );
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre</span>
                {rowData.first_name}
            </>
        );
    };

    const lastnameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Apellido</span>
                {rowData.last_name}
            </>
        );
    };

    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {/* <Rating value={rowData.rating} readonly cancel={false} /> */}
                {rowData.email}
            </>
        );
    };

    const rolBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Rol</span>
                {rowData.role.description}
            </>
        );
    };

    const statusBodyTemplate = (rowData) => {
        // 4-habilitado
        // 5-deshabilitado
        var result = rowData.stateId == 4 ? true : false;
        return (
            <>
                <span className="p-column-title">Status</span>
                <i className={classNames("pi", { "text-green-500 pi-check-circle": result, "text-pink-500 pi-times-circle": !result })}></i>
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        const _setDeleteButton = _user.id == rowData.id ? false : true;
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editUser(rowData)} tooltip="Editar usuario" />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" disabled={!_setDeleteButton} onClick={() => confirmDeleteUser(rowData)} tooltip="Eliminar usuario" />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Listado de usuarios</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const userDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveUser} />
        </>
    );
    const deleteUserDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUserDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteUser} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>
                    <DataTable
                        ref={dt}
                        value={users}
                        selection={selectedUser}
                        onSelectionChange={(e) => setSelectedUser(e.value)}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} usuarios"
                        globalFilter={globalFilter}
                        emptyMessage="No se encontraron usuarios."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column field="id" header="Id" sortable body={codeBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="first_name" header="Nombre" sortable body={nameBodyTemplate} headerStyle={{ width: "15%", minWidth: "10rem" }}></Column>
                        <Column field="last_name" header="Apellido" sortable body={lastnameBodyTemplate} headerStyle={{ width: "15%", minWidth: "10rem" }}></Column>
                        <Column field="email" header="Email" body={emailBodyTemplate} sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="role.description" header="Rol" body={rolBodyTemplate} sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="status" header="Estado" body={statusBodyTemplate} sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column body={actionBodyTemplate} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
                    </DataTable>

                    <Dialog visible={userDialog} style={{ width: "500px" }} header="Usuario" modal className="p-fluid" footer={userDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="name">Nombre</label>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-user"></i>
                                </span>
                                <InputText id="first_name" value={user.first_name} maxLength="20" onChange={(e) => onInputChange(e, "first_name")} required autoFocus className={classNames({ "p-invalid": submitted && !user.first_name })} />
                            </div>
                            {submitted && !user.first_name && <small className="p-invalid">El nombre es obligatorio</small>}
                        </div>

                        <div className="field">
                            <label htmlFor="name">Apellido</label>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-user"></i>
                                </span>
                                <InputText id="last_name" value={user.last_name} maxLength="20" onChange={(e) => onInputChange(e, "last_name")} required className={classNames({ "p-invalid": submitted && !user.last_name })} />
                            </div>
                            {submitted && !user.last_name && <small className="p-invalid">El apellido es obligatorio</small>}
                        </div>

                        <div className="field">
                            <label htmlFor="email">Email</label>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-at"></i>
                                </span>
                                <InputText id="email" value={user.email} onChange={(e) => onInputChange(e, "email")} required className={classNames({ "p-invalid": submitted && (!user.email || !validateEmail(user.email)) })} />
                            </div>
                            {submitted && !validateEmail(user.email) && (
                                <small className="p-invalid">
                                    El email ingresado no es valido <br></br>
                                </small>
                            )}
                        </div>
                        <div className="field">
                            <label htmlFor="name">Perfil</label>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-user"></i>
                                </span>
                                <Dropdown value={user.roleId} onChange={(e) => onSelectChange(e)} options={roleSelectItems} required className={classNames({ "p-invalid": submitted && !user.roleId })} optionLabel="name" optionValue="code" placeholder="Seleccione rol" />
                            </div>
                            {submitted && !user.roleId && <small className="p-invalid">El rol es obligatorio</small>}
                        </div>

                        <div className="field">
                            <label htmlFor="sii_pass">Contraseña</label>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-lock"></i>
                                </span>
                                <InputText id="password" type="password" maxLength="16" onChange={(e) => onInputChange(e, "password")} required className={classNames({ "p-invalid": submitted && (!user.password || !validatePassword(user.password)) })} />
                            </div>
                            {submitted && !user.password && <small className="p-invalid"></small>}
                            {submitted && !validatePassword(user.password) && (
                                <small className="p-invalid">
                                    La clave ingresada no es valida, debe tener al menos 6 caracteres entre ellos un numero y un caracter especial <br></br>
                                </small>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteUserDialog} style={{ width: "450px" }} header="Confirmar" modal footer={deleteUserDialogFooter} onHide={hideDeleteUserDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {user && (
                                <span>
                                    ¿Esta seguro de eliminar <b>{user.first_name + " " + user.last_name}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Crud, comparisonFn);

import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { OperationService } from "../service/OperationService";
import { ClientService } from "../service/ClientService";
import { Loading } from "../components/Loading";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";


const Crud = () => {
    let emptyOperation = {
        id: null,
        name: "",
        userId: null,
        clientId: null,
        documentId: null,
        stateId: null,
    };

    const [operations, setOperations] = useState(null);
    const [assigned, setAssigned] = useState(null);

    const [loaded, setLoader] = useState(true);
    const [globalFilter, setGlobalFilter] = useState(null);

    const [operation, setOperation] = useState(emptyOperation);
    const [operationDialog, setOperationDialog] = useState(false);

    const [loaded_operations, setOperationTable] = useState(false);
    const [loaded_assigned, setAssignedTable] = useState(false);

    const toast = useRef(null);
    const dt = useRef(null);
    const [globalFilterAssign, setGlobalFilterAssign] = useState(null);
    const dt2 = useRef(null);

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (loaded_operations && loaded_assigned) {
            setLoader(false);
        }
    }, [loaded_operations, loaded_assigned]);

    function loadData() {
        setOperationTable(false);
        setAssignedTable(false);

        const loggedUserJson = JSON.parse(window.localStorage.getItem("loggedRabenUser"));
        const operationsService = new OperationService();
        operationsService.getOperationsByUser(loggedUserJson.id).then((data) => {
            setAssigned(data);
            setAssignedTable(true);
        });

        operationsService.getOperationsAvailable().then((data) => {
            setOperations(data);
            setOperationTable(true);
        });
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const nameClientBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Razón social</span>
                {rowData.name}
            </>
        );
    };

    const folioBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Folio</span>
                {rowData.folio}
            </>
        );
    };
    const rutClientBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Id</span>
                {rowData.rut + "-" + rowData.dv}
            </>
        );
    };

    const rutDeudorBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Rut deudor</span>
                {rowData.rut_documento}
            </>
        );
    };

    const nameDeudorBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Razón social deudor</span>
                {rowData.razon_social}
            </>
        );
    };

    const netoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Neto</span>
                {formatCurrency(rowData.neto)}
            </>
        );
    };
    const ivaBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">IVA</span>
                {formatCurrency(rowData.iva)}
            </>
        );
    };
    const totalBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Total</span>
                {formatCurrency(rowData.total)}
            </>
        );
    };
    const fechaEmisionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha emisión</span>
                {formatFullDate(rowData.fecha_emision)}
            </>
        );
    };
    const nuevoBodyTemplate = (rowData) => {
        // 1-nuevo
        // 2-vigente

        var result = rowData.nuevo == 1 ? true : false;
        return (
            <>
                <span className="p-column-title">Estado</span>
                <i className={classNames("pi", { "text-green-500 pi-check-circle": result, "text-pink-500 pi-times-circle": !result })}></i>
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        let _detailLink = "/clientDetail/" + rowData.idCliente;
        return (
            <div className="actions">
                {/* <Button icon="pi pi-bookmark" className="p-button-rounded p-button-primary mr-2" onClick={() => followNew(rowData)} tooltip="Asignar opreación" /> */}
                <NavLink aria-label="resumen" to={_detailLink}>
                    <Button icon="pi pi-user" className="p-button-rounded p-button-secondary mr-2" tooltip="Ver resumen de cliente" />
                </NavLink>
                
            </div>
        );
    };

    const exportCSVAssign = () => {
        dt2.current.exportCSV();
    };

    const actionAssignBodyTemplate = (rowData) => {
        
        let _detailLink = "/operationHistory/" + rowData.id + "/" + rowData.clientId;
        let _clientDetailLink = "/clientDetail/" + rowData.clientId;
        return (
            <div className="actions">
                <NavLink aria-label="resumen" to={_detailLink}>
                    <Button icon="pi pi-window-maximize" className="p-button-rounded p-button-primary mr-2" tooltip="Ver Operación" />
                </NavLink>
                <NavLink aria-label="resumen" to={_clientDetailLink}>
                    <Button icon="pi pi-user" className="p-button-rounded p-button-secondary mr-2" tooltip="Ver resumen de cliente" />
                </NavLink>
            </div>
        );
    };
    const nameAssignBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre Operación</span>
                {rowData.name}
            </>
        );
    };

    const folioAssignBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Folio</span>
                {rowData.document.folio}
            </>
        );
    };

    const fechaEmisionAssignBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha emisión</span>
                {rowData.document.fecha_emision ? formatFullDate(rowData.document.fecha_emision) : "-"}
            </>
        );
    };

    const totalAssignBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Total</span>
                {formatCurrency(rowData.document.total)}
            </>
        );
    };

    const rutClientAssignBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Id</span>
                {rowData.document.rut + "-" + rowData.document.dv}
            </>
        );
    };

    const rutDeudorAssignBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Rut deudor</span>
                {rowData.document.rut_documento}
            </>
        );
    };

    const nameDeudorAssignBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Razón social deudor</span>
                {rowData.document.razon_social}
            </>
        );
    };

    const formatCurrency = (value) => {
        return value.toLocaleString("es-CL", { style: "currency", currency: "CLP" });
    };
    const formatFullDate = (value) => {
        var date = value.split("T");
        var _date = date[0].split("-");
        return _date[2] + "-" + _date[1] + "-" + _date[0];
    };

    const followNew = async (document) => {
        let _operation = {
            id: null,
            name: "",
            userId: null,
            clientId: null,
            documentId: null,
            stateId: null,
        };

        const clientService = new ClientService();
        var _client = await clientService.getClientByRut(document.rut).then();

        _operation.name = document.folio + "-" + document.name;
        _operation.documentId = document.idDocumento;
        _operation.clientId = _client.id;
        setOperation(_operation);
        setOperationDialog(true);
    };

    const hideDialog = () => {
        setOperationDialog(false);
    };

    const saveOperation = () => {
        setLoader(true);
        const operationsService = new OperationService();
        operationsService
            .setOperation(operation)
            .then((d) => {
                setOperationDialog(false);
                setOperation(emptyOperation);
                toast.current.show({ severity: "success", summary: "Correcto", detail: "Operacion Asignada", life: 3000 });
            })
            .catch((error) => {
                setOperation(emptyOperation);
                setOperationDialog(false);
                toast.current.show({ severity: "error", summary: error.message, detail: "Error", life: 3000 });
            })
            .finally(() => {
                loadData();
                setLoader(false);
            });
        setLoader(false);
    };

    const operationDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveOperation} />
        </>
    );

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => {
                        setGlobalFilter(e.target.value);
                    }}
                    placeholder="Buscar..."
                />
            </span>
            <Button label="Exportar" icon="pi pi-download" className="p-button-help" onClick={exportCSV} />
        </div>
    );

    const headerAssign = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => {
                        setGlobalFilterAssign(e.target.value);
                    }}
                    placeholder="Buscar..."
                />
            </span>
            <Button label="Exportar" icon="pi pi-download" className="p-button-help" onClick={exportCSVAssign} />
        </div>
    );
    return (
        <div className="grid crud-demo">
            {loaded && <Loading />}
            <div className="col-12">
                        {/* <p className="m-0">
                            <div className="card">
                            <h5>Listado de asignaciones</h5>
                                <DataTable
                                    ref={dt2}
                                    value={assigned}
                                    paginator
                                    rows={5}
                                    rowsPerPageOptions={[10, 25, 30]}
                                    className="datatable-responsive"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} operaciones"
                                    globalFilter={globalFilterAssign}
                                    emptyMessage="No se encontraron asignaciones."
                                    header={headerAssign}
                                    responsiveLayout="scroll"
                                >
                                    <Column field="name" header="Nombre operación" body={nameAssignBodyTemplate} sortable headerStyle={{ width: "30%", minWidth: "10rem" }}></Column>
                                    <Column field="document.rut" header="Rut cliente" sortable body={rutClientAssignBodyTemplate} headerStyle={{ width: "5%", minWidth: "8rem" }}></Column>
                                    <Column field="document.folio" header="Folio" sortable body={folioAssignBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                                    <Column field="document.rut_documento" header="Rut deudor" body={rutDeudorAssignBodyTemplate} sortable headerStyle={{ width: "%", minWidth: "8rem" }}></Column>
                                    <Column field="document.razon_social" header="Razón social deudor" body={nameDeudorAssignBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                                    <Column field="document.fecha_emision" header="Fecha emisión" body={fechaEmisionAssignBodyTemplate} sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                                    <Column field="document.total" header="Total" body={totalAssignBodyTemplate} sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>

                                    <Column body={actionAssignBodyTemplate} headerStyle={{ width: "8%", minWidth: "8rem" }}></Column>
                                </DataTable>
                            </div>
                        </p> */}

                        <p className="m-0">
                            <div className="card">
                            <h5>Listado de operaciones disponibles</h5>
                                <Toast ref={toast} />
                                <DataTable
                                    ref={dt}
                                    value={operations}
                                    paginator
                                    rows={5}
                                    rowsPerPageOptions={[10, 25, 30]}
                                    className="datatable-responsive"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} operaciones"
                                    globalFilter={globalFilter}
                                    emptyMessage="No se encontraron operaciones."
                                    header={header}
                                    responsiveLayout="scroll"
                                >
                                    <Column field="rut" header="Rut cliente" sortable body={rutClientBodyTemplate} headerStyle={{ width: "5%", minWidth: "8rem" }}></Column>
                                    <Column field="name" header="Razón social cliente" body={nameClientBodyTemplate} headerStyle={{ width: "30%", minWidth: "10rem" }}></Column>
                                    <Column field="folio" header="Folio" sortable body={folioBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                                    <Column field="rut_documento" header="Rut deudor" body={rutDeudorBodyTemplate} headerStyle={{ width: "%", minWidth: "8rem" }}></Column>
                                    <Column field="razon_social" header="Razón social deudor" body={nameDeudorBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                                    {/* <Column field="code" header="Código" body={codigoDeudorBodyTemplate} sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column> */}
                                    <Column field="neto" header="Neto" body={netoBodyTemplate} sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                                    <Column field="iva" header="IVA" body={ivaBodyTemplate} sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                                    <Column field="total" header="Total" body={totalBodyTemplate} sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                                    <Column field="fecha_emision" header="Fecha emisión" body={fechaEmisionBodyTemplate} sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                                    <Column field="nuevo" header="Nueva operación" body={nuevoBodyTemplate} sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                                    <Column body={actionBodyTemplate} headerStyle={{ width: "8%", minWidth: "8rem" }}></Column>
                                </DataTable>
                            </div>
                        </p>

                <Dialog visible={operationDialog} style={{ width: "450px" }} header="Asignar Operación" modal className="p-fluid" footer={operationDialogFooter} onHide={hideDialog}>
                    <div className="flex align-items-center justify-content-center">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                        {operation && (
                            <span>
                                ¿Esta seguro de asignar la operación del cliente <b>{operation.name}</b> con folio <b>{operation.folio}</b> por un total de <b>{operation.total}</b>?
                            </span>
                        )}
                    </div>
                </Dialog>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Crud, comparisonFn);

import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Toast } from "primereact/toast";
import { NavLink } from "react-router-dom";
import { Button } from "primereact/button";
import { ClientService } from "../service/ClientService";
import { Sii_responseService } from "../service/Sii_responseService";
import { OperationService } from "../service/OperationService";
import { BlacklistService } from "../service/BlacklistService";
import { Sii_sectionService } from "../service/Sii_sectionService";
import { TableSellsClient } from "../components/TableSellsClient";
import { TableTransfersClient } from "../components/TableTransfersClient";
import { TableOperationsClient } from "../components/TableOperationsClient";
import { Loading } from "../components/Loading";
import { Dialog } from "primereact/dialog";

const Crud = (props) => {
    let emptyClient = {
        id: null,
        name: "",
        rut: "",
        fullRut: "",
        dv: "",
        sii_pass: "",
        email: "",
        createdAt: "",
        updatedAt: "",
        stateId: 1,
        state: {},
    };
    const [client, setClient] = useState(emptyClient);
    const [sii_rvd, setRvd] = useState({});
    const [sii_rtc, setRtc] = useState({});
    const [sii_ope, setOpe] = useState({});
    const [loaded_sells, setSellTable] = useState(false);
    const [loaded_transfers, setTransferTable] = useState(false);
    const [loaded_operations, setOperationTable] = useState(false);
    const [loaded, setLoader] = useState(true);
    const [errorDialog, setErrorDialog] = useState(false);
    const params = useParams();

    const [blacklist, setBlacklist] = useState({});
    const [sii_section, setSiiSection] = useState({});
    const toast = useRef(null);

    useEffect(() => {
        let clientId = params.id;

        const clientService = new ClientService();
        clientService.getClient(clientId).then((data) => {
            setClient(data);
            const siiService = new Sii_responseService();
            //sells
            siiService
                .getRCVD(data.fullRut,"", "RVD", 0)
                .then((res) => {
                    setRvd(res);
                    setSellTable(true);
                })
                .catch((error) => {
                    setSellTable(false);
                    toast.current.show({ severity: "error", summary: "Error en la comunicación", detail: error.response.data.message, life: 3000 });
                    setErrorDialog(true);
                    setLoader(false);
                });
            //Transfers
            siiService
                .getRTCL(data.fullRut, "RTC")
                .then((res) => {
                    setRtc(res);
                    setTransferTable(true);
                })
                .catch((error) => {
                    setTransferTable(false);
                    toast.current.show({ severity: "error", summary: "Error en la comunicación", detail: error.response.data.message, life: 3000 });
                    setErrorDialog(true);
                    setLoader(false);
                });

            //Operation
            const opeService = new OperationService();
            opeService
                .getOperationsClient(data.fullRut)
                .then((res) => {
                    setOpe(res);
                    setOperationTable(true);
                })
                .catch((error) => {
                    setOperationTable(false);
                    toast.current.show({ severity: "error", summary: "Error en la comunicación", detail: error.response.data.message, life: 3000 });
                    setErrorDialog(true);
                    setLoader(false);
                });


            const blacklistService = new BlacklistService();
            blacklistService.get(data.rut).then((res) => {
                setBlacklist(res);
            });

            const sii_sectionService = new Sii_sectionService();
            sii_sectionService.get(data.rut).then((res) => {
                setSiiSection(res);
            });
        });
    }, []);

    const hideErrorDialog = () => {
        setErrorDialog(false);
    };

    useEffect(() => {
        if (loaded_sells && loaded_operations && loaded_transfers) {
            setLoader(false);
        }
    }, [loaded_sells, loaded_operations, loaded_transfers]);

    const clientData = () => {


        return (
            <div className="col-12 xl:col-6">
                <div className="card">
                    <h5>Datos Cliente</h5>
                    <ul className="list-none p-0 m-0">
                        <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Rut</span>
                                <div className="mt-1 text-600">{client.fullRut}</div>
                            </div>
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Estado de clave SII</span>
                                <div className="mt-1 text-green-600">{client.state.description}</div>
                            </div>
                        </li>

                        <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Nombre Cliente</span>
                                <div className="mt-1 text-600">{client.name}</div>
                            </div>
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Correo Electronico</span>
                                <div className="mt-1 text-600">{client.email}</div>
                            </div>
                        </li>

                        
                    </ul>
                </div>
            </div>
        );
    };

    const clientSummary = () => {
        var calculatedPercent = sii_section.section * 7.7;
        function color(percent) {
            if (parseFloat(percent) >= 70) {
                return "green";
            }
            if (parseFloat(percent) >= 40) {
                return "yellow";
            } else {
                return "pink";
            }
        }
        return (
            <div className="col-12 xl:col-6">
                <div className="card">
                    <h5>Resumen Cliente</h5>
                    <ul className="list-none p-0 m-0">
                    <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Lista negra</span>
                                <div className="mt-1 text-600">{`${blacklist ? blacklist.type : "Sin información"}`}</div>
                            </div>
                        </li>
                        <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Tramo de Ventas</span>
                                <div className="mt-1 text-600">{`${sii_section ? "Tramo" + sii_section.section : "Sin información"}`}</div>
                            </div>
                            <div className="mt-2 md:mt-0 ml-0 md:ml-8 flex align-items-center">
                                <div className="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style={{ height: "8px" }}>
                                    <div className={`bg-${color(calculatedPercent)}-500 h-full`} style={{ width: calculatedPercent }} />
                                </div>
                                <span className={`${"text-" + color(calculatedPercent) + "-500 ml-3 font-medium"}`}>{`${sii_section ? calculatedPercent + " %" : ""}`}</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        );
    };

    const errorDialogFooter = (
        <>
            <NavLink aria-label="" to={"/client/"}>
                <Button label="Aceptar" icon="pi pi-times" className="p-button-text" onClick={hideErrorDialog} />
            </NavLink>
        </>
    );


    return (
        <div className="grid crud ">
            <Toast ref={toast} />
            {loaded && <Loading />}
            {clientData()}
            {clientSummary()}
            {loaded_operations && <TableOperationsClient model={sii_ope} />}
            {loaded_sells && <TableSellsClient model={sii_rvd.response} />}
            {loaded_transfers && <TableTransfersClient model={sii_rtc.response} />}
            <Dialog visible={errorDialog} style={{ width: "450px" }} header="Error" modal footer={errorDialogFooter} onHide={hideErrorDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />

                    <span>
                        <b>Ha ocurrido un error en la comunicación con el SII, por favor intente mas tarde, recuerde no bloquear la clave del cliente.</b>
                    </span>
                </div>
            </Dialog>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Crud, comparisonFn);

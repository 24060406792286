import axios from "axios";

const URI_Client = process.env.REACT_APP_API_URI + "/api/operation_client/";
const URI_Aviable = process.env.REACT_APP_API_URI + "/api/operation_available/";
const URI = process.env.REACT_APP_API_URI + "/api/operation/";
const URI_Op_ByUser = process.env.REACT_APP_API_URI + "/api/operationByUser/";

const _user = JSON.parse(window.localStorage.getItem("loggedRabenUser"));

export class OperationService {
    async getOperation(id) {
        return await axios
            .get(URI + id, {
                headers: {
                    authorization: _user.token,
                },
            })
            .then((res) => res.data);
    }

    async getOperationsClient(rut, clave) {
        return await axios
            .post(
                URI_Client,
                {
                    rut: rut,
                    sii_password: clave,
                },
                {
                    headers: {
                        authorization: _user.token,
                    },
                }
            )
            .then((res) => res.data);
    }

    async getOperationsAvailable() {
        return await axios
            .get(URI_Aviable + _user.id + "/" + _user.roleId, {
                headers: {
                    authorization: _user.token,
                },
            })
            .then((res) => res.data);
    }

    async setOperation(operation) {
        return await axios
            .post(
                URI,
                {
                    name: operation.name,
                    userId: _user.id,
                    clientId: operation.clientId,
                    documentId: operation.documentId,
                    stateId: 6,
                },
                {
                    headers: {
                        authorization: _user.token,
                    },
                }
            )
            .then((res) => res.data);
    }

    async getOperationsByUser(userId) {
        return await axios
            .get(URI_Op_ByUser + userId, {
                headers: {
                    authorization: _user.token,
                },
            })
            .then((res) => res.data);
    }
}

import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { ClientService } from "../service/ClientService";
import { Sii_responseService } from "../service/Sii_responseService";
import { UserService } from "../service/UserService";
import { Loading } from "../components/Loading";
import { DashboardService } from "../service/DashboardService";
import { MenuClient } from "../components/menu/MenuClient";

const _user = JSON.parse(window.localStorage.getItem("loggedRabenUser"));

const Crud = () => {
    let emptyClient = {
        id: null,
        name: "",
        rut: "",
        fullRut: "",
        dv: "",
        sii_pass: "",
        email: "",
        phone: "",
        createdAt: "",
        updatedAt: "",
        userId: _user.id,
        stateId: 1,
    };

    const [clients, setClients] = useState(null);
    const [users, setUsers] = useState(null);
    const [clientDialog, setClientDialog] = useState(false);
    const [clientDetailDialog, setClientDetailDialog] = useState(false);
    const [deleteClientDialog, setDeleteClientDialog] = useState(false);
    const [updateClientDialog, setUpdateClientDialog] = useState(false);
    const [client, setClient] = useState(emptyClient);
    const [selectedClients, setSelectedClients] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loaded, setLoader] = useState(true);
    const [resLast24h, setResLast24h] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        const clientService = new ClientService();
        clientService.getClients().then((data) => {
            setClients(data);
            setLoader(false);
        });

        const userService = new UserService();
        userService.getUsers().then((data) => setUsers(data));

        const dashboardService = new DashboardService();
        dashboardService.getResponses24h().then((data) => {
            setResLast24h(data);
        });
    }, []);

    const reqTotalResponse = () => {
        let total = 0;
        if (resLast24h) {
            resLast24h.forEach((element) => {
                total = total + parseInt(element.request);
            });
        }
        return total > 1390 ? false : true;
    };

    const openNew = () => {
        setClient(emptyClient);
        setSubmitted(false);
        setClientDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setClientDialog(false);
    };

    const openDetailClient = (client) => {
        //Get client PASS
        setLoader(true);
        const clientService = new ClientService();
        var valClient = clientService.getClientSii(client.id).then((data) => {
            setClient(data);
            setClientDetailDialog(true);
            setLoader(false);
        });
    };

    const viewPass = () => {
        var x = document.getElementById("sii_pass_view");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
    };

    const hideDetailDialog = () => {
        setClientDetailDialog(false);
    };

    const hideDeleteClientDialog = () => {
        setDeleteClientDialog(false);
    };

    var Fn = {
        // Valida el rut con su cadena completa "XXXXXXXX-X"
        validaRut: function (rutCompleto) {
            rutCompleto = rutCompleto.replace("‐", "-");
            if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) {
                return false;
            }

            var tmp = rutCompleto.split("-");
            var digv = tmp[1];
            var rut = tmp[0];

            if (digv === "K") digv = "k";

            return Fn.dv(rut) == digv;
        },
        dv: function (T) {
            var M = 0,
                S = 1;
            for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;

            return S ? S - 1 : "k";
        },
    };

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };

    const validatePhone = (phone) => {
        return String(phone)
            .toLowerCase()
            .match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/);
    };

    const onSelectChange = (e) => {
        const val = (e.target && e.target.value) || "";
        let _client = { ...client };
        _client[`userId`] = val;
        setClient(_client);
    };

    const saveClient = async () => {
        setSubmitted(true);
        if (client.name.trim() && client.fullRut.trim() && client.sii_pass.trim()) {
            if (Fn.validaRut(client.fullRut) && validateEmail(client.email) && validatePhone(client.phone)) {
                var tmp = client.fullRut.split("-");
                var rut = tmp[0];
                const clientService = new ClientService();
                var valClient = await clientService.getClientByRut(rut).then();

                if (!valClient || client.id) {
                    setLoader(true);

                    let _client = { ...client };
                    //sells
                    const siiService = new Sii_responseService();
                    siiService
                        .getRCVD(client.fullRut, client.sii_pass, "RVD", 1)
                        .then((res) => {
                            if (client.id) {
                                console.log(client);
                                clientService.updateClient(_client).then((d) => {
                                    clientService.getClients().then((data) => setClients(data));
                                    setClient(emptyClient);
                                    toast.current.show({ severity: "success", summary: "Correcto", detail: "Cliente Actualizado", life: 3000 });
                                    setLoader(false);
                                });
                            } else {
                                clientService.setClient(_client).then((d) => {
                                    clientService.getClients().then((data) => setClients(data));
                                    setClient(emptyClient);
                                    toast.current.show({ severity: "success", summary: "Correcto", detail: "Cliente", life: 3000 });
                                    setLoader(false);
                                });
                            }
                            setClientDialog(false);
                        })
                        .catch((error) => {
                            setLoader(false);
                            clientService.getClients().then((data) => setClients(data));
                            toast.current.show({ severity: "error", summary: "Error en la comunicación", detail: error.response.data.message, life: 3000 });
                        })
                        .finally(() => {
                            setLoader(false);
                        });
                } else {
                    toast.current.show({ severity: "error", summary: "Error en ingreso de rut", detail: "El rut ingresado ya existe", life: 3000 });
                }
            }
        }
    };

    const editClient = (client) => {
        setClient({ ...client });
        setClientDialog(true);
    };
    const confirmUpdateClient = (client) => {
        setClient(client);
        setUpdateClientDialog(true);
    };

    const hideUpdateClientDialog = () => {
        setUpdateClientDialog(false);
    };

    const confirmDeleteClient = (client) => {
        setClient(client);
        setDeleteClientDialog(true);
    };

    const deleteClient = () => {
        setLoader(true);
        const clientService = new ClientService();
        clientService
            .deleteClient(client.id)
            .then((d) => {
                clientService.getClients().then((data) => setClients(data));
                setDeleteClientDialog(false);
                setClient(emptyClient);
                toast.current.show({ severity: "success", summary: "Correcto", detail: "Cliente Eliminado", life: 3000 });
            })
            .catch((error) => {
                setClient(emptyClient);
                setDeleteClientDialog(false);
                toast.current.show({ severity: "error", summary: error.message, detail: "Error", life: 3000 });
            })
            .finally(() => {
                setLoader(false);
            });
    };

    const updateClient = () => {
        setLoader(true);
        const clientService = new ClientService();
        const sii_responseService = new Sii_responseService();
        sii_responseService
            .updateResponsePeriods(client.rut)
            .then((d) => {
                clientService.getClients().then((data) => setClients(data));
                setUpdateClientDialog(false);
                setClient(emptyClient);
                toast.current.show({ severity: "success", summary: "Correcto", detail: "Resgistros actualizados", life: 3000 });
                let _detailLink = "/clientDetail/" + client.id;
                window.location.hash = _detailLink;
            })
            .catch((error) => {
                setClient(emptyClient);
                setUpdateClientDialog(false);
                toast.current.show({ severity: "error", summary: error.message, detail: "Error", life: 3000 });
            })
            .finally(() => {
                setLoader(false);
            });
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _client = { ...client };
        _client[`${name}`] = val;

        setClient(_client);
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo Cliente" icon="pi pi-user-plus" className="p-button-success mr-2" onClick={openNew} />
                <Button label="Exportar" icon="pi pi-download" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Id</span>
                {rowData.id}
            </>
        );
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre</span>
                {rowData.name}
            </>
        );
    };

    const rutBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Rut</span>
                {rowData.fullRut}
            </>
        );
    };

    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {/* <Rating value={rowData.rating} readonly cancel={false} /> */}
                {rowData.email}
            </>
        );
    };

    const statusBodyTemplate = (rowData) => {
        // 1-activo
        // 2-desactualizado
        // 3- espera

        var state = rowData.stateId == 1 ? true : false;
        return (
            <>
                <span className="p-column-title">Status</span>
                <i className={classNames("pi", { "text-green-500 pi-check-circle": state, "text-pink-500 pi-times-circle": !state })}></i>
            </>
        );
    };

    const originBodyTemplate = (rowData) => {
        // 1-Formulario de registro

        var state = rowData.register_form == 1 ? true : false;
        return (
            <>
                <span className="p-column-title">Status</span>
                <i className={classNames("pi", { "text-blue-500 pi-globe": state, "text-bluegray-500 pi-ellipsis-h": !state })}></i>
            </>
        );
    };

    const formatFullDate = (value) => {
        var date = value.split("T");
        var _date = date[0].split("-");
        return _date[2] + "-" + _date[1] + "-" + _date[0];
    };

    const createdAtTemplate = (rowData) => {
        return formatFullDate(rowData.createdAt);
    };

    const userBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">User</span>
                {rowData.user.fullName}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        let _detailLink = "/clientDetail/" + rowData.id;
        let _operationDetailLink = "/operationDetail/" + rowData.id;
        const loggedUserJson = JSON.parse(window.localStorage.getItem("loggedRabenUser"));
        const admin = loggedUserJson.roleId == 1 ? true : false;
        var state = rowData.stateId == 1 ? true : false;

        const options = [
            {
                label: "Opciones",
                items: [],
            },
        ];

        if (state && reqTotalResponse()) {
            options[0].items.push(
                {
                    label: "Resumen",
                    icon: "pi pi-window-maximize",
                    command: () => {
                        window.location.hash = _detailLink;
                    },
                },
                {
                    label: "Operaciones",
                    icon: "pi pi-eye",
                    command: () => {
                        window.location.hash = _operationDetailLink;
                    },
                }
            );
        }

        if (admin) {
            if (state && reqTotalResponse()) {
                options[0].items.push({
                    label: "Forzar actualización",
                    icon: "pi pi-refresh",
                    command: () => {
                        confirmUpdateClient(rowData);
                    },
                });
            }

            options.push({
                label: "Configuración",
                items: [],
            });
            options[1].items.push(
                {
                    label: "Editar",
                    icon: "pi pi-pencil",
                    command: () => {
                        editClient(rowData);
                    },
                },
                {
                    label: "Detalles",
                    icon: "pi pi-lock-open",
                    command: () => {
                        openDetailClient(rowData);
                    },
                },
                {
                    label: "Eliminar",
                    icon: "pi pi-fw pi-trash",
                    command: () => {
                        confirmDeleteClient(rowData);
                    },
                }
            );
        }
        return (
            <div className="actions">
                <MenuClient model={options} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Listado de clientes</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const clientDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveClient} />
        </>
    );

    const clientDetailDialogFooter = (
        <>
            <Button label="Volver" icon="pi pi-times" className="p-button-text" onClick={hideDetailDialog} />
        </>
    );

    const deleteClientDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteClientDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteClient} />
        </>
    );

    const updateClientDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideUpdateClientDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={updateClient} />
        </>
    );

    return (
        <div className="grid crud-demo">
            {loaded && <Loading />}
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>
                    <DataTable
                        ref={dt}
                        value={clients}
                        selection={selectedClients}
                        onSelectionChange={(e) => setSelectedClients(e.value)}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} clientes"
                        globalFilter={globalFilter}
                        emptyMessage="No se encontraron clientes."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column field="id" header="Id" sortable body={codeBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="name" header="Nombre / Razón Social" sortable body={nameBodyTemplate} headerStyle={{ width: "35%", minWidth: "10rem" }}></Column>
                        <Column field="fullRut" header="Rut" sortable body={rutBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="email" header="Email" body={emailBodyTemplate} sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="user.fullName" header="Ejecutivo" body={userBodyTemplate} sortable headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
                        <Column field="stateId" header="Estado" body={statusBodyTemplate} sortable headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
                        <Column field="register_form" header="Origen" body={originBodyTemplate} sortable headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
                        <Column field="createdAt" header="Fecha de creación" body={createdAtTemplate} sortable headerStyle={{ width: "15%", minWidth: "10rem" }}></Column>
                        <Column body={actionBodyTemplate} headerStyle={{ width: "10%", minWidth: "15rem" }}></Column>
                    </DataTable>

                    <Dialog visible={clientDialog} style={{ width: "450px" }} header="Cliente" modal className="p-fluid" footer={clientDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="name">Nombre / Razón social</label>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-user"></i>
                                </span>
                                <InputText id="name" value={client.name} onChange={(e) => onInputChange(e, "name")} required autoFocus className={classNames({ "p-invalid": submitted && !client.name })} />
                            </div>
                            {submitted && !client.name && <small className="p-invalid">Nombre es obligatorio</small>}
                        </div>

                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="fullRut">Rut</label>
                                <InputText id="fullRut" value={client.fullRut} onChange={(e) => onInputChange(e, "fullRut")} required className={classNames({ "p-invalid": submitted && (!client.fullRut || !Fn.validaRut(client.fullRut)) })} />
                                {submitted && !Fn.validaRut(client.fullRut) && (
                                    <small className="p-invalid">
                                        El rut ingresado no es valido <br></br>
                                    </small>
                                )}
                                {submitted && !client.fullRut && <small className="p-invalid">El rut es obligatorio</small>}
                            </div>
                        </div>

                        <div className="field">
                            <label htmlFor="email">Email</label>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-at"></i>
                                </span>
                                <InputText id="email" value={client.email} onChange={(e) => onInputChange(e, "email")} required className={classNames({ "p-invalid": submitted && (!client.email || !validateEmail(client.email)) })} />
                            </div>
                            {submitted && !validateEmail(client.email) && (
                                <small className="p-invalid">
                                    El email ingresado no es valido <br></br>
                                </small>
                            )}
                            {submitted && !client.email && <small className="p-invalid">El email es obligatorio</small>}
                        </div>

                        <div className="field">
                            <label htmlFor="phone">Teléfono</label>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-phone"></i>
                                </span>
                                <InputText id="phone" maxLength="40" value={client.phone} onChange={(e) => onInputChange(e, "phone")} required className={classNames({ "p-invalid": submitted && (!client.phone || !validatePhone(client.phone)) })} />
                            </div>
                            {submitted && !validatePhone(client.phone) && (
                                <small className="p-invalid">
                                    El teléfono ingresado no es valido <br></br>
                                </small>
                            )}
                            {submitted && !client.phone && <small className="p-invalid">El teléfono es obligatorio</small>}
                        </div>

                        <div className="field">
                            <label htmlFor="sii_pass">Contraseña SII</label>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-lock"></i>
                                </span>
                                <InputText type="password" id="sii_pass" value={client.sii_pass} onChange={(e) => onInputChange(e, "sii_pass")} required className={classNames({ "p-invalid": submitted && !client.sii_pass })} />
                            </div>
                            {submitted && !client.sii_pass && <small className="p-invalid">La clave es obligatoria</small>}
                        </div>

                        {_user.roleId === 1 && (
                            <div className="field">
                                <label htmlFor="name">Ejecutivo</label>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-user"></i>
                                    </span>
                                    <Dropdown value={client.userId} onChange={(e) => onSelectChange(e)} options={users} required className={classNames({ "p-invalid": submitted && !client.userId })} optionLabel="fullName" optionValue="id" placeholder="Seleccione Ejecutivo" />
                                </div>
                                {submitted && !client.userId && <small className="p-invalid">El ejecutivo es obligatorio</small>}
                            </div>
                        )}
                    </Dialog>

                    <Dialog visible={clientDetailDialog} style={{ width: "450px" }} header="Cliente" modal className="p-fluid" footer={clientDetailDialogFooter} onHide={hideDetailDialog}>
                        <div className="field">
                            <label htmlFor="name" className="text-900 font-medium mr-2 mb-1 md:mb-0">Nombre / Razón social</label>
                            <div className="p-inputgroup">
                                <label htmlFor="name">{client.name}</label>
                            </div>
                        </div>

                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="fullRut" className="text-900 font-medium mr-2 mb-1 md:mb-0">Rut</label>
                                <div className="p-inputgroup">
                                    <label htmlFor="fullRut">{client.fullRut}</label>
                                </div>
                            </div>
                        </div>

                        <div className="field">
                            <label htmlFor="email" className="text-900 font-medium mr-2 mb-1 md:mb-0">Email</label>
                            <div className="p-inputgroup">
                                <label htmlFor="email">{client.email}</label>
                            </div>
                        </div>

                        <div className="field">
                            <label htmlFor="phone" className="text-900 font-medium mr-2 mb-1 md:mb-0">Teléfono</label>
                            <div className="p-inputgroup">
                                <label htmlFor="phone">{client.phone}</label>
                            </div>
                        </div>

                        <div className="field">
                            <label htmlFor="sii_pass" className="text-900 font-medium mr-2 mb-1 md:mb-0">Contraseña SII</label>
                            <div className="p-inputgroup">
                                <Button icon="pi pi-lock" className="p-button-success" onClick={viewPass} />
                                <InputText type="password" id="sii_pass_view" value={client.sii_pass} />
                            </div>
                        </div>
                    </Dialog>

                    <Dialog visible={deleteClientDialog} style={{ width: "450px" }} header="Confirmación" modal footer={deleteClientDialogFooter} onHide={hideDeleteClientDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {client && (
                                <span>
                                    ¿Esta seguro de eliminar <b>{client.name}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={updateClientDialog} style={{ width: "450px" }} header="Confirmación" modal footer={updateClientDialogFooter} onHide={hideUpdateClientDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {client && (
                                <span>
                                    Ten en cuenta que forzar la actualización de <b>{client.name}</b> puede ser arriesgado y solo se recomienda en casos excepcionales debido a la posible respuesta del SII. Por favor, evalúa cuidadosamente los riesgos involucrados antes de tomar una decisión
                                </span>
                            )}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Crud, comparisonFn);

import axios from "axios";

const URI = process.env.REACT_APP_API_URI + "/api/client/";
const _user = JSON.parse(window.localStorage.getItem("loggedRabenUser"));

export class ClientService {
    async getClients() {
        return await axios
            .get(URI + "userid/" + _user.id + "/" + _user.roleId, {
                headers: {
                    authorization: _user.token,
                },
            })
            .then((res) => res.data);
    }

    async getClient(id) {
        return await axios
            .get(URI + "id/" + id, {
                headers: {
                    authorization: _user.token,
                },
            })
            .then((res) => res.data);
    }

    async getClientSii(id) {
        return await axios
            .get(URI + "sii/" + id + "/" + _user.id, {
                headers: {
                    authorization: _user.token,
                },
            })
            .then((res) => res.data);
    }

    async getClientByRut(rut) {
        return await axios.get(URI + "rut/" + rut).then((res) => res.data);
    }

    async setClient(client) {
        return await axios
            .post(
                URI,
                {
                    name: client.name,
                    sii_pass: client.sii_pass,
                    email: client.email,
                    fullRut: client.fullRut,
                    userId: client.userId,
                    phone: client.phone,
                    register_form: 1,
                    stateId: 1,
                },
                {
                    headers: {
                        authorization: _user.token,
                    },
                }
            )
            .then((res) => res.data);
    }

    async setPublicClient(client) {
        return await axios
            .post(URI + "public", {
                name: client.name,
                sii_pass: client.sii_pass,
                email: client.email,
                fullRut: client.fullRut,
                userId: client.userId,
                phone: client.phone,
                register_form: 1,
                stateId: 1,
            })
            .then((res) => res.data);
    }

    async updateClient(client) {
        return await axios
            .put(
                URI + client.id,
                {
                    name: client.name,
                    fullRut: client.fullRut,
                    sii_pass: client.sii_pass,
                    email: client.email,
                    phone: client.phone,
                    userId: client.userId,
                    register_form: 0,
                    stateId: 1,
                },
                {
                    headers: {
                        authorization: _user.token,
                    },
                }
            )
            .then((res) => res.data);
    }

    async deleteClient(id) {
        return await axios
            .delete(URI + id, {
                headers: {
                    authorization: _user.token,
                },
            })
            .then((res) => res.data);
    }
}

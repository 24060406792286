import axios from 'axios';

const URI = process.env.REACT_APP_API_URI+'/api/login/';

export class LoginService {

    async login(login) {
        return await axios.post(URI, {
            email: login.email,
            password: login.password
        }).then(res => res.data);
        
    }

}
import React, { useState, useEffect } from "react";
import { Link,useHistory } from "react-router-dom";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { SessionService } from "./service/SessionService.js";


export const AppTopbar = (props) => {
    const [logoutDialog, setLogoutDialog] = useState(false);
    const [user, setUser] = useState({});
    const history = useHistory();

    useEffect(() => {
        const sessionService = new SessionService();
        sessionService.getSessionData().then((data) => setUser(data));
    }, []);

    const confirmLogout = () => {
        setLogoutDialog(true);
    };

    const hideLogoutDialog = () => {
        setLogoutDialog(false);
    };

    const logout = () => {
        history.push('/')
        window.localStorage.removeItem("loggedRabenUser");
        window.localStorage.removeItem("member");
        window.location.reload();
    };

    const logoutDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideLogoutDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={logout} />
        </>
    );

    return (
        <div className="layout-topbar">
            <Link to="/" className="layout-topbar-logo">
                <img src={props.layoutColorMode === "light" ? "assets/layout/images/Logo-raben-white.png" : "assets/layout/images/Logo-raben-dark.png"} alt="logo" />
            </Link>

            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars" />
            </button>

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>
            <ul className={classNames("layout-topbar-menu lg:flex origin-top", { "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive })}>
                <li>
                    <span className="text-800 font-medium text-xl">
                        Bienvenido {user.first_name} {user.last_name}                        
                    </span>
                    <br></br>
                    <span className="text-600 font-small text-l">
                    {user.role}                     
                    </span>
                        
                    <button className="p-link layout-topbar-button" onClick={() => confirmLogout()}>
                        <i className="pi pi-power-off" />
                        <span>Salir</span>
                    </button>
                </li>
            </ul>

            <Dialog visible={logoutDialog} style={{ width: "450px" }} header="Cerrar sesion" modal footer={logoutDialogFooter} onHide={hideLogoutDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    <span>¿Esta seguro que desea cerrar sesion?</span>
                </div>
            </Dialog>
        </div>
    );
};

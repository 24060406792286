import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";

export const TableOperationsClient = (props) => {
    const [globalFilter, setGlobalFilter] = useState(null);

    useEffect(() => {}, []);

    const formatFullDate = (value) => {
        var date = value.split("T");
        var _date = date[0].split("-")
        return _date[2]+"-"+_date[1]+"-"+_date[0];
    };
    const formatCurrency = (value) => {
        return value.toLocaleString("es-CL", { style: "currency", currency: "CLP" });
    };

    const subDateBodyTemplate = (rowData) => {
        return formatFullDate(rowData.fecha);
    };
    const subCodigoTemplate = (rowData) => {
        return rowData.dte;
    };
    const subFolioTemplate = (rowData) => {
        return rowData.folio;
    };
    const subRutTemplate = (rowData) => {
        return rowData.rut;
    };
    const subRazonSocialTemplate = (rowData) => {
        return rowData.razon_social;
    };

    const subNetoBodyTemplate = (rowData) => {
        return formatCurrency(parseInt(rowData.neto));
    };
    const subivaBodyTemplate = (rowData) => {
        return formatCurrency(parseInt(rowData.iva));
    };
    const subTotalBodyTemplate = (rowData) => {
        return formatCurrency(parseInt(rowData.total));
    };
    const Header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Listado de operaciones</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    return (
        <div className="col-12">
            <div className="card">
            <DataTable
                    value={props.model}
                    responsiveLayout="scroll"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    header={Header}
                    globalFilter={globalFilter}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} documentos"
                    emptyMessage="No se encontraron documentos."
                >
                    <Column field="fecha" header="Fecha" body={subDateBodyTemplate}></Column>
                    <Column field="folio" header="Folio" sortable body={subFolioTemplate}></Column>   
                    <Column field="dte" header="Código" body={subCodigoTemplate}></Column>
                    <Column field="rut" header="Rut" sortable body={subRutTemplate}></Column>
                    <Column field="razon_social" header="Razón social" sortable body={subRazonSocialTemplate}></Column>
                    <Column field="neto" header="Neto" body={subNetoBodyTemplate}></Column>
                    <Column field="iva" header="IVA" body={subivaBodyTemplate}></Column>
                    <Column field="total" header="Total" body={subTotalBodyTemplate}></Column>
                </DataTable>
            </div>
        </div>
    );
};

import React from "react";
import { Chart } from "primereact/chart";

export const ChartBuySellData = (props) => {
    const lineOptions = {
        plugins: {
            legend: {
                labels: {
                    color: "grey",
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    color: "grey",
                },
                grid: {
                    color: "rgba(160, 167, 181, .3)",
                },
            },
            y: {
                ticks: {
                    color: "grey",
                },
                grid: {
                    color: "rgba(160, 167, 181, .3)",
                },
            },
        },
    };

    const barOptions = {
        plugins: {
            legend: {
                labels: {
                    color: "grey",
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    color: "grey",
                },
                grid: {
                    color: "rgba(160, 167, 181, .3)",
                },
            },
            y: {
                ticks: {
                    color: "grey",
                },
                grid: {
                    color: "rgba(160, 167, 181, .3)",
                },
            },
        },
    };

    function chartBarData() {
        var _labels = [];
        var _sellData = [];
        var _buyData = [];


        if (props.modelrvd) {
            props.modelrvd.forEach((element) => {

                _sellData.push(element.total_periodo);
                _labels.push(formatDate(element.period));
            });
        }
        if (props.modelrcd) {
            props.modelrcd.forEach((element) => {
                _buyData.push(element.total_periodo);
            });
        }

        var average = [];
        var i;
        for (i = 0; i < _sellData.length; i++) {
            average.push((_sellData[i] + _buyData[i]) / 2);
        }

        const barData = {
            labels: _labels,
            datasets: [
                {
                    type: "line",
                    tension: 0.4,
                    label: "Promedio",
                    borderColor: "#42A5F5",
                    fill: false,
                    data: average,
                },
                {
                    label: "Compras",
                    backgroundColor: "green",
                    data: _buyData,
                },
                {
                    label: "Ventas",
                    backgroundColor: "purple",
                    data: _sellData,
                },
            ],
        };
        return barData;
    }

    function chartLinearData() {
        var _labels = [];
        var _sellData = [];
        var _buyData = [];
        var _ncSellData=[];

        if (props.modelrvd) {
            props.modelrvd.forEach((element) => {
                _ncSellData.push(element.totalNC_periodo);
                _sellData.push(element.total_periodo);
                _labels.push(formatDate(element.period));
            });
        }
        if (props.modelrcd) {
            props.modelrcd.forEach((element) => {
                _buyData.push(element.total_periodo);
            });
        }

        const lineData = {
            labels: _labels,
            datasets: [
                {
                    label: "NC",
                    data: _ncSellData,
                    fill: false,
                    backgroundColor: "black",
                    borderColor: "orange",
                    tension: 0.4,
                },
                {
                    type: "bar",
                    label: "Ventas",
                    backgroundColor: "purple",
                    data: _sellData
                },


            ],
        };
        return lineData;
    }

    const formatDate = (value) => {
        var date = value.split("T");
        var _date = date[0].split("-")
        return _date[1]+"-"+_date[0];
    };

    return (
        <div className="col-12">
            <div className="grid">
                <div className="col-12 xl:col-6">
                    <div className="card">
                        <h5>Evolucion de ventas</h5>
                        <Chart type="bar" data={chartBarData()} options={lineOptions} />
                    </div>
                </div>
                <div className="col-12 xl:col-6">
                    <div className="card">
                        <h5>Relación ventas / NC</h5>
                        <Chart type="line" data={chartLinearData()} options={barOptions} />
                    </div>
                </div>
            </div>
        </div>
    );
};
import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { Toast } from "primereact/toast";
import { NavLink } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { ClientService } from "../service/ClientService";
import { Sii_responseService } from "../service/Sii_responseService";
import { BlacklistService } from "../service/BlacklistService";
import { Sii_sectionService } from "../service/Sii_sectionService";
import { TableSellsClient } from "../components/TableSellsClient";
import { TableBuysClient } from "../components/TableBuysClient";
import { ChartBuySellData } from "../components/ChartBuySellData";
import { Loading } from "../components/Loading";
import { Dialog } from "primereact/dialog";

const Crud = (props) => {
    let emptyClient = {
        id: null,
        name: "",
        rut: "",
        fullRut: "",
        dv: "",
        sii_pass: "",
        email: "",
        createdAt: "",
        updatedAt: "",
        stateId: 1,
        state: {},
    };

    let emptyDeudor = {
        fullRut: "",
    };
    const [client, setClient] = useState(emptyClient);
    const [deudor, setDeudor] = useState(emptyDeudor);
    const [sii_rvd, setRvd] = useState({});
    const [sii_rcd, setRcd] = useState({});
    const [loaded_sells, setSellTable] = useState(false);
    const [loaded_buys, setBuyTable] = useState(false);
    const params = useParams();
    const [loaded, setLoader] = useState(true);
    const [errorDialog, setErrorDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [blacklist, setBlacklist] = useState(null);
    const [blacklistDeudor, setBlacklistDeudor] = useState(null);
    const [deudorDialog, setDeudorDialog] = useState(false);
    const [sii_section, setSiiSection] = useState(null);
    const [sii_sectionDeudor, setSiiSectionDeudor] = useState(null);
    const toast = useRef(null);
    const _operationDetailLink = "/operationDetail/" + params.id;

    useEffect(() => {
        let clientId = params.id;
        const clientService = new ClientService();
        clientService.getClient(clientId).then((data) => {
            setClient(data);
            const siiService = new Sii_responseService();
            //sells
            siiService
                .getRCVD(data.fullRut, "", "RVD", 0)
                .then((res) => {
                    setRvd(res);
                    setSellTable(true);
                })
                .catch((error) => {
                    setSellTable(false);
                    toast.current.show({ severity: "error", summary: "Error en la comunicación", detail: error.response.data.message, life: 3000 });
                    setErrorDialog(true);
                    setLoader(false);
                });
            //buys
            siiService
                .getRCVD(data.fullRut, "", "RCD", 0)
                .then((res) => {
                    setRcd(res);
                    setBuyTable(true);
                })
                .catch((error) => {
                    setSellTable(false);
                    toast.current.show({ severity: "error", summary: "Error en la comunicación", detail: error.response.data.message, life: 3000 });
                    setErrorDialog(true);
                    setLoader(false);
                });

            //Transfers
            siiService
                .getRTCL(data.fullRut, "RTC")
                .then((res) => {})
                .catch((error) => {
                    toast.current.show({ severity: "error", summary: "Error en la comunicación", detail: error.response.data.message, life: 3000 });
                    setErrorDialog(true);
                    setLoader(false);
                });

            const blacklistService = new BlacklistService();
            blacklistService.get(data.rut).then((res) => {
                setBlacklist(res);
            });

            const sii_sectionService = new Sii_sectionService();
            sii_sectionService.get(data.rut).then((res) => {
                setSiiSection(res);
            });
        });
    }, []);

    useEffect(() => {
        if (loaded_sells && loaded_buys) {
            setLoader(false);
        }
    }, [loaded_sells, loaded_buys]);

    const hideErrorDialog = () => {
        setErrorDialog(false);
    };
    const clientData = () => {
        return (
            <div className="col-12 xl:col-6">
                <div className="card">
                    <h5>Datos Cliente</h5>
                    <ul className="list-none p-0 m-0">
                        <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Rut</span>
                                <div className="mt-1 text-600">{client.fullRut}</div>
                            </div>
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Estado de clave SII</span>
                                <div className="mt-1 text-green-600">{client.state.description}</div>
                            </div>
                        </li>

                        <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Nombre Cliente</span>
                                <div className="mt-1 text-600">{client.name}</div>
                            </div>
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Correo Electronico</span>
                                <div className="mt-1 text-600">{client.email}</div>
                            </div>
                        </li>

                        <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Lista negra</span>
                                <div className="mt-1 text-600">{`${blacklist ? blacklist.type : "Sin información"}`}</div>
                            </div>
                            <Button label="Agregar deudor" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Lista negra deudor {deudor.fullRut}</span>
                                <div className="mt-1 text-600">{`${blacklistDeudor ? blacklistDeudor.type : "Sin información"}`}</div>
                            </div>
                        </li>
                        <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Tramo de ventas</span>
                                <div className="mt-1 text-600">{`${sii_section ? "Tramo " + sii_section.section : "Sin información"}`}</div>
                            </div>
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Tramo de ventas deudor {deudor.fullRut}</span>
                                <div className="mt-1 text-600">{`${sii_sectionDeudor ? "Tramo " + sii_sectionDeudor.section : "Sin información"}`}</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        );
    };

    const clientSummary = () => {
        const totalSells = sii_rvd.response
            ? sii_rvd.response.reduce((accumulator, object) => {
                  return accumulator + object.total_periodo;
              }, 0)
            : 0;
        const totalBuys = sii_rcd.response
            ? sii_rcd.response.reduce((accumulator, object) => {
                  return accumulator + object.total_periodo;
              }, 0)
            : 0;
        const totalPeriod = sii_rvd.response ? sii_rvd.response.length : 0;
        const sellsAvg = sii_rvd.response ? parseInt(totalSells) / parseInt(totalPeriod) : 0;
        const buysAvg = sii_rcd.response ? parseInt(totalBuys) / parseInt(totalPeriod) : 0;

        const totalNCSells = sii_rvd.response
            ? sii_rvd.response.reduce((accumulator, object) => {
                  return accumulator + object.totalNC_periodo;
              }, 0)
            : 0;
        const sellsNCAvg = sii_rvd.response ? parseInt(totalNCSells) / parseInt(totalPeriod) : 0;

        return (
            <div className="col-12 xl:col-6">
                <div className="card">
                    <h5>Resumen Cliente en un periodo de {totalPeriod} meses</h5>
                    <ul className="list-none p-0 m-0">
                        <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Total de ventas</span>
                                <div className="mt-1 text-600"> {formatCurrency(totalSells)}</div>
                            </div>
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Total de NC en ventas</span>
                                <div className="mt-1 text-600">{formatCurrency(totalNCSells)}</div>
                            </div>
                        </li>

                        <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Promedio de ventas</span>
                                <div className="mt-1 text-600"> {formatCurrency(sellsAvg)}</div>
                            </div>
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Promedio de NC en ventas</span>
                                <div className="mt-1 text-600">{formatCurrency(sellsNCAvg)}</div>
                            </div>
                        </li>
                        <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Promedio de compras</span>
                                <div className="mt-1 text-600">{formatCurrency(buysAvg)}</div>
                            </div>
                        </li>
                        <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
                            <div>
                                <div className="mt-1 text-600">
                                    <NavLink aria-label="" to={_operationDetailLink}>
                                        {/* <Button icon="pi pi-eye" className="p-button-rounded p-button-secondary mr-2" /> */}
                                        <Button icon="pi pi-eye" label="Ver operaciones disponibles" className="p-button-info" />
                                    </NavLink>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        );
    };

    const formatDate = (value) => {
        var _date = new Date(value);
        var options = { year: "numeric", month: "long" };
        return _date.toLocaleString("es-CL", options);
    };
    const formatCurrency = (value) => {
        return value.toLocaleString("es-CL", { style: "currency", currency: "CLP" });
    };
    const errorDialogFooter = (
        <>
            <NavLink aria-label="" to={"/client/"}>
                <Button label="Aceptar" icon="pi pi-times" className="p-button-text" onClick={hideErrorDialog} />
            </NavLink>
        </>
    );

    var Fn = {
        // Valida el rut con su cadena completa "XXXXXXXX-X"
        validaRut: function (rutCompleto) {
            rutCompleto = rutCompleto.replace("‐", "-");
            if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) {
                return false;
            }

            var tmp = rutCompleto.split("-");
            var digv = tmp[1];
            var rut = tmp[0];
            if (digv === "K") digv = "k";
            return Fn.dv(rut) == digv;
        },
        dv: function (T) {
            var M = 0,
                S = 1;
            for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
            return S ? S - 1 : "k";
        },
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _deudor = { ...deudor };
        _deudor[`${name}`] = val;

        setDeudor(_deudor);
    };

    const openNew = () => {
        setDeudor(emptyDeudor);
        setBlacklistDeudor(null);
        setSiiSectionDeudor(null);
        setSubmitted(false);
        setDeudorDialog(true);
    };
    const hideDialog = () => {
        setDeudor(emptyDeudor);
        setBlacklistDeudor(null);
        setSiiSectionDeudor(null);
        setSubmitted(false);
        setDeudorDialog(false);
    };
    const searchDeudor = () => {
        setSubmitted(true);
        if (deudor.fullRut.trim()) {
            if (Fn.validaRut(deudor.fullRut)) {
                setDeudorDialog(false);
                var tmp = deudor.fullRut.split("-");
                const blacklistService = new BlacklistService();
                blacklistService.get(tmp[0]).then((res) => {
                    setBlacklistDeudor(res);
                });
                const sii_sectionService = new Sii_sectionService();
                sii_sectionService.get(tmp[0]).then((res) => {
                    setSiiSectionDeudor(res);
                });
            }
        }
    };

    const deudorDialogFooter = (
        <>
            <Button label="Consultar" icon="pi pi-check" className="p-button-text" onClick={searchDeudor} />
        </>
    );

    return (
        <div className="grid crud ">
            <Toast ref={toast} />
            {loaded && <Loading />}
            {clientData()}
            {clientSummary()}
            {loaded_sells && <TableSellsClient model={sii_rvd.response} />}
            {loaded_buys && <TableBuysClient model={sii_rcd.response} />}
            {loaded_buys && loaded_sells && <ChartBuySellData colorMode={props.colorMode} modelrcd={sii_rcd.response} modelrvd={sii_rvd.response} />}

            <Dialog visible={errorDialog} style={{ width: "450px" }} header="Error" modal footer={errorDialogFooter} onHide={hideErrorDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />

                    <span>
                        <b>Ha ocurrido un error en la comunicación con el SII, por favor intente mas tarde, recuerde no bloquear la clave del cliente.</b>
                    </span>
                </div>
            </Dialog>

            <Dialog visible={deudorDialog} style={{ width: "450px" }} header="Cliente" modal className="p-fluid" footer={deudorDialogFooter} onHide={hideDialog}>
                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="fullRut">Rut</label>
                        <InputText id="fullRut" value={deudor.fullRut} onChange={(e) => onInputChange(e, "fullRut")} required className={classNames({ "p-invalid": submitted && (!deudor.fullRut || !Fn.validaRut(deudor.fullRut)) })} />
                        {submitted && !Fn.validaRut(deudor.fullRut) && (
                            <small className="p-invalid">
                                El rut ingresado no es valido <br></br>
                            </small>
                        )}
                        {submitted && !deudor.fullRut && <small className="p-invalid">El rut es obligatorio</small>}
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Crud, comparisonFn);

import React, { useRef } from "react";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";

export const MenuClient = (props) => {
    const menu = useRef(null);
    return (
        <div>
            <Menu model={props.model} popup ref={menu} />
            <Button label="" icon="pi pi-fw pi-cog" onClick={(e) => menu.current.toggle(e)} />
        </div>
    );
};
